import { BlobServiceClient } from '@azure/storage-blob';
import UploadedFileStore from '../../../core/store/uploadedFile.store/uploadedFile.store';
import HttpService from '../../../core/interceptor/api.handler';
import { deleteTheFileInStorageArray } from '../uploadingFileStatus/uploadingFileStatus';

// Map to store AbortControllers for each file upload
const abortControllers = new Map();
const _httpService = new HttpService();
let sasUrl: string = '';
const baseURL: string | undefined = process.env.REACT_APP_QUOTE_BASE_URL;
// Check if baseURL is defined before proceeding
if (!baseURL) {
    throw new Error('REACT_APP_API_BASE_URL is not defined in the environment variables.');
}

export async function getSasUrl() {
    const response: string = await _httpService.get(`${baseURL}/patentservice/getsasurl`, {
        headers: {
            Accept: '*/*'
        }
    });
    sasUrl = response;
    return sasUrl;
}

export async function handleAzureStorageUploadTheFile(
    file: File,
    azureBlobFolderPath: string,
    azureBlobFileName: string
) {
    const blobServiceClient = new BlobServiceClient(sasUrl);
    const containerClient = blobServiceClient.getContainerClient(azureBlobFolderPath);

    try {
        const blobClient = containerClient.getBlockBlobClient(azureBlobFileName);
        const fileSize = file.size;

        const onprogress = (ev: { loadedBytes: number }) => {
            const percentage = (ev.loadedBytes / fileSize) * 100;
            UploadedFileStore.UploadingFileStatus(file.name, percentage.toFixed(2));
        };

        const controller = new AbortController();
        abortControllers.set(file.name, controller);

        const isAzureUploadSkip: boolean =
            (process.env.REACT_APP_UPLOAD_TO_AZURE_STORAGE ?? 'true').toLowerCase() === 'true';

        try {
            if (isAzureUploadSkip) {
                await blobClient.uploadData(file, {
                    blockSize: 4 * 1024 * 1024,
                    concurrency: 20,
                    onProgress: onprogress,
                    blobHTTPHeaders: { blobContentType: file.type },
                    abortSignal: controller.signal
                });
            }
        } catch (error: any) {
            if (error.name !== 'AbortError') {
                console.error(`Error occurred during upload of ${file.name}:`, error);
            }
        }
    } catch (error) {
        console.error('Error occurred during upload:', error);
    }
}
export function cancelFileUpload(fileName: string) {
    const controller = abortControllers.get(fileName);
    if (controller) {
        controller.abort();
        deleteTheFileInStorageArray(fileName);
    }
}
