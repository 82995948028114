import { RouteObject, redirect } from 'react-router-dom';

import { protectedLoader } from './providers/authProvider/authProvider';
import Layout from 'layouts/layout';
import ErrorPage from 'pages/errorPage/errorPage';
import AuthStore from 'core/store/authStore/authStore';
import { PATHS } from 'constants/routes.constant';
import { Permissions } from 'constants/permissions';

const orderQuoteModuleChildren = [
    {
        path: '',
        async lazy() {
            const ServiceType = await import(
                'pages/createOrderAndQuote/components/serviceType/components/serviceType'
            );
            return {
                Component: ServiceType.default
            };
        }
    },
    {
        path: PATHS.SERVICE_TYPE,
        async lazy() {
            const ServiceType = await import(
                'pages/createOrderAndQuote/components/serviceType/components/serviceType'
            );
            return {
                Component: ServiceType.default
            };
        }
    },
    {
        path: PATHS.COMPANY_DETAILS,
        async lazy() {
            const CompanyDetails = await import(
                'pages/createOrderAndQuote/components/companyDetails/components/companyDetails'
            );
            return {
                Component: CompanyDetails.default
            };
        }
    },
    {
        path: PATHS.ADDITIONAL_INFO,
        async lazy() {
            const AdditionalInfo = await import(
                'pages/createOrderAndQuote/components/additionalInfo/components/additionalInfo'
            );
            return {
                Component: AdditionalInfo.default
            };
        }
    },
    {
        path: PATHS.PATENT_DETAILS,
        async lazy() {
            const PatentDetails = await import(
                'pages/createOrderAndQuote/components/patentDetails/components/patentDetails'
            );
            return {
                Component: PatentDetails.default
            };
        }
    },
    {
        path: PATHS.SELECT_COUNTRY,
        async lazy() {
            const SelectCountry = await import('pages/orders/selectCountry/selectCountry');
            return {
                Component: SelectCountry.default
            };
        }
    },
    {
        path: PATHS.FILE_UPLOAD,
        async lazy() {
            const OrderAndQuoteFileUpload = await import(
                'pages/createOrderAndQuote/components/orderAndQuoteFileUpload/orderAndQuoteFileUpload'
            );
            return {
                Component: OrderAndQuoteFileUpload.default
            };
        }
    },
    {
        path: PATHS.SUMMARY,
        async lazy() {
            const Summary = await import('pages/createOrderAndQuote/components/summary/summary');
            return {
                Component: Summary.default
            };
        }
    },
    {
        path: `${PATHS.ORDER_QUOTE_DETAILS}`,
        async lazy() {
            const QuoteDetails = await import(
                'pages/createOrderAndQuote/components/orderQuoteDetails/components/orderQuoteDetails'
            );
            return {
                Component: QuoteDetails.default
            };
        }
    },
    {
        path: `${PATHS.LANGUAGE_SELECTION}`,
        async lazy() {
            const LanguageSelection = await import(
                'pages/createOrderAndQuote/components/languageSelection/components/languageSelection'
            );
            return {
                Component: LanguageSelection.default
            };
        }
    }
];

export const router: RouteObject[] = [
    {
        id: 'root',
        path: '/',
        loader: protectedLoader,
        errorElement: <ErrorPage />,
        Component: Layout,
        children: [
            {
                index: true,
                async lazy() {
                    const Dashboard = await import('pages/dashboard/dashboard');
                    return {
                        Component: Dashboard.default
                    };
                }
            },
            {
                path: PATHS.ACCOUNT_SETTINGS,
                async lazy() {
                    const accountSettings = await import('pages/accountSettings/accountSettings');
                    return {
                        Component: accountSettings.default
                    };
                }
            },
            {
                path: 'task-management',
                async lazy() {
                    const TasksList = await import('pages/taskManagement/tasksList/tasksList');
                    return {
                        Component: TasksList.default
                    };
                }
            },
            {
                path: 'task-management/details/:taskId/:orderRequestNumber',
                async lazy() {
                    const taskDetailPage = await import(
                        'pages/taskManagement/ViewTask/viewTaskDetail'
                    );
                    return {
                        Component: taskDetailPage.default
                    };
                }
            },

            {
                path: `${PATHS.TASK_MANAGEMENT}/${PATHS.CREATE_TASK}`,
                async lazy() {
                    const CreateManualTaskPage = await import(
                        'pages/taskManagement/createManualTask/createManualTask'
                    );
                    return {
                        Component: CreateManualTaskPage.default
                    };
                },
                children: [
                    {
                        path: PATHS.TASK_DETAILS,
                        async lazy() {
                            const CreateTaskDetailsPage = await import(
                                'pages/taskManagement/createManualTask/createTaskSteppers/createTaskDetails'
                            );
                            return {
                                Component: CreateTaskDetailsPage.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,
                        async lazy() {
                            const TaskFileUploadPage = await import(
                                'pages/taskManagement/createManualTask/createTaskSteppers/taskFileUpload'
                            );
                            return {
                                Component: TaskFileUploadPage.default
                            };
                        }
                    }
                ]
            },
            {
                path: 'users',
                async lazy() {
                    const usersPage = await import('pages/users/users');
                    return {
                        Component: usersPage.default
                    };
                }
            },
            {
                path: 'companies',
                async lazy() {
                    const CompaniesPage = await import('pages/companies/companies');
                    return {
                        Component: CompaniesPage.default
                    };
                }
            },
            {
                path: 'orders-quotes',
                async lazy() {
                    const OrdersAndQuotesPage = await import(
                        'pages/ordersAndQuotes/ordersAndQuotes'
                    );
                    return {
                        Component: OrdersAndQuotesPage.default
                    };
                }
            },
            {
                path: PATHS.ACCOUNT_INVOICES,
                async lazy() {
                    const AccountingInvoices = await import('pages/accounting/invoices/invoices');
                    return {
                        Component: AccountingInvoices.default
                    };
                }
            },
            {
                path: `${PATHS.ACCOUNT_INVOICES}/details/:invoiceId`,
                async lazy() {
                    const AccountingInvoicesDetails = await import(
                        'pages/accounting/invoiceDetails/invoiceDetails'
                    );
                    return {
                        Component: AccountingInvoicesDetails.default
                    };
                }
            },
            {
                path: PATHS.INVOICES,
                async lazy() {
                    const AccountingInvoices = await import('pages/accounting/invoices/invoices');
                    return {
                        Component: AccountingInvoices.default
                    };
                }
            },
            {
                path: `${PATHS.INVOICES}/details/:invoiceId`,
                async lazy() {
                    const AccountingInvoicesDetails = await import(
                        'pages/accounting/invoiceDetails/invoiceDetails'
                    );
                    return {
                        Component: AccountingInvoicesDetails.default
                    };
                }
            },
            {
                path: 'billing-terms',
                async lazy() {
                    const BillingTermsPage = await import(
                        'pages/settingsPages/pages/billingTerm/billingTerms'
                    );
                    return {
                        Component: BillingTermsPage.default
                    };
                }
            },
            {
                path: 'tasks',
                async lazy() {
                    const TasksPage = await import('pages/settingsPages/pages/tasks/tasks');
                    return {
                        Component: TasksPage.default
                    };
                }
            },
            {
                path: 'request-currencies',
                async lazy() {
                    const RequestCurrenciesPage = await import(
                        'pages/settingsPages/pages/requestCurrencies/requestCurrencies'
                    );
                    return {
                        Component: RequestCurrenciesPage.default
                    };
                }
            },
            {
                path: 'vendor-currencies',
                async lazy() {
                    const VendorCurrenciesPage = await import(
                        'pages/settingsPages/pages/vendorCurrencies/vendorCurrencies'
                    );
                    return {
                        Component: VendorCurrenciesPage.default
                    };
                }
            },
            {
                path: 'languages',
                async lazy() {
                    const LanguagesPage = await import(
                        'pages/settingsPages/pages/languagesPage/languages'
                    );
                    return {
                        Component: LanguagesPage.default
                    };
                }
            },
            {
                path: 'units',
                async lazy() {
                    const UnitsPage = await import('pages/settingsPages/pages/units/units');
                    return {
                        Component: UnitsPage.default
                    };
                }
            },
            {
                path: 'ip-instruction-deadlines',
                async lazy() {
                    const IpInstructionDeadlinesPage = await import(
                        'pages/settingsPages/pages/ipInstructionDeadlines/ipInstructionDeadlines'
                    );
                    return {
                        Component: IpInstructionDeadlinesPage.default
                    };
                }
            },
            {
                path: 'payment-methods',
                async lazy() {
                    const PaymentMethodsPage = await import(
                        'pages/settingsPages/pages/paymentMethods/paymentMethods'
                    );
                    return {
                        Component: PaymentMethodsPage.default
                    };
                }
            },
            {
                path: 'tax-forms',
                async lazy() {
                    const TaxForms = await import('pages/settingsPages/pages/taxForms/taxForms');
                    return {
                        Component: TaxForms.default
                    };
                }
            },
            {
                path: 'break-down',
                async lazy() {
                    const BreakDown = await import('pages/settingsPages/pages/breakDown/breakDown');
                    return {
                        Component: BreakDown.default
                    };
                }
            },
            {
                path: 'service-type',
                async lazy() {
                    const ServiceType = await import(
                        'pages/createOrderAndQuote/components/serviceType/components/serviceType'
                    );
                    return {
                        Component: ServiceType.default
                    };
                }
            },
            {
                path: PATHS.PV_SETTINGS,
                async lazy() {
                    const PVSettings = await import('pages/pvsettings/pvSettings');
                    return {
                        Component: PVSettings.default
                    };
                }
            },
            {
                path: `${PATHS.ORDER_QUOTE}/${PATHS.CREATE}`,
                async lazy() {
                    const CreateOrderAndQuote = await import(
                        'pages/createOrderAndQuote/createOrderAndQuote'
                    );
                    return {
                        Component: CreateOrderAndQuote.default
                    };
                },
                children: orderQuoteModuleChildren
            },
            {
                path: `${PATHS.ORDER_QUOTE}/${PATHS.EDIT}`,
                async lazy() {
                    const CreateOrderAndQuote = await import(
                        'pages/createOrderAndQuote/createOrderAndQuote'
                    );
                    return {
                        Component: CreateOrderAndQuote.default
                    };
                },
                children: orderQuoteModuleChildren
            },
            {
                path: `${PATHS.COMPANIES}/${PATHS.CREATE}`,
                loader: (args) => protectedLoader(args, Permissions.CREATE_COMPANIES), // Pass required permission here
                async lazy() {
                    const CreateCompany = await import('pages/companies/createCompany');
                    return {
                        Component: CreateCompany.default
                    };
                },
                children: [
                    {
                        path: PATHS.COMPANY_PROFILE,
                        async lazy() {
                            const CompanyProfile = await import(
                                'pages/companies/companySteppers/companyProfile'
                            );
                            return {
                                Component: CompanyProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.BILLING_INFO,
                        async lazy() {
                            const BillingInfo = await import(
                                'pages/companies/companySteppers/billingInfo'
                            );
                            return {
                                Component: BillingInfo.default
                            };
                        }
                    },
                    {
                        path: PATHS.CONTACTS,
                        async lazy() {
                            const Contacts = await import(
                                'pages/companies/companySteppers/contacts/contactsList'
                            );
                            return {
                                Component: Contacts.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,
                        async lazy() {
                            const companyFileUpload = await import(
                                'pages/companies/companySteppers/companyFileUpload'
                            );
                            return {
                                Component: companyFileUpload.default
                            };
                        }
                    },
                    {
                        path: PATHS.RATES,
                        async lazy() {
                            const companyRates = await import(
                                'pages/companies/companySteppers/companyRates/companyRates'
                            );
                            return {
                                Component: companyRates.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.COMPANIES}/${PATHS.EDIT}/:company_id?`,
                loader: (args) => protectedLoader(args, Permissions.EDIT_COMPANIES), // Pass required permission here
                async lazy() {
                    const CreateCompany = await import('pages/companies/createCompany');
                    return {
                        Component: CreateCompany.default
                    };
                },
                children: [
                    {
                        path: PATHS.COMPANY_PROFILE,
                        async lazy() {
                            const CompanyProfile = await import(
                                'pages/companies/companySteppers/companyProfile'
                            );
                            return {
                                Component: CompanyProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.BILLING_INFO,
                        async lazy() {
                            const BillingInfo = await import(
                                'pages/companies/companySteppers/billingInfo'
                            );
                            return {
                                Component: BillingInfo.default
                            };
                        }
                    },
                    {
                        path: PATHS.CONTACTS,
                        async lazy() {
                            const Contacts = await import(
                                'pages/companies/companySteppers/contacts/contactsList'
                            );
                            return {
                                Component: Contacts.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,
                        async lazy() {
                            const companyFileUpload = await import(
                                'pages/companies/companySteppers/companyFileUpload'
                            );
                            return {
                                Component: companyFileUpload.default
                            };
                        }
                    },
                    {
                        path: PATHS.RATES,
                        async lazy() {
                            const companyRates = await import(
                                'pages/companies/companySteppers/companyRates/companyRates'
                            );
                            return {
                                Component: companyRates.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.COMPANIES}/${PATHS.VIEW}/:company_id?`,
                loader: (args) => protectedLoader(args, Permissions.VIEW_COMPANIES), // Pass required permission here
                async lazy() {
                    const CreateCompany = await import('pages/companies/createCompany');
                    return {
                        Component: CreateCompany.default
                    };
                },
                children: [
                    {
                        path: PATHS.COMPANY_PROFILE,
                        async lazy() {
                            const CompanyProfile = await import(
                                'pages/companies/companySteppers/companyProfile'
                            );
                            return {
                                Component: CompanyProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.BILLING_INFO,
                        async lazy() {
                            const BillingInfo = await import(
                                'pages/companies/companySteppers/billingInfo'
                            );
                            return {
                                Component: BillingInfo.default
                            };
                        }
                    },
                    {
                        path: PATHS.CONTACTS,
                        async lazy() {
                            const Contacts = await import(
                                'pages/companies/companySteppers/contacts/contactsList'
                            );
                            return {
                                Component: Contacts.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,
                        async lazy() {
                            const companyFileUpload = await import(
                                'pages/companies/companySteppers/companyFileUpload'
                            );
                            return {
                                Component: companyFileUpload.default
                            };
                        }
                    },
                    {
                        path: PATHS.RATES,
                        async lazy() {
                            const companyRates = await import(
                                'pages/companies/companySteppers/companyRates/companyRates'
                            );
                            return {
                                Component: companyRates.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.STAFF_USER}/${PATHS.CREATE}`,
                loader: (args) => protectedLoader(args, Permissions.CREATE_STAFF_USER), // Pass required permission here

                async lazy() {
                    const CreateStaffUser = await import('pages/users/createStaffUsers');
                    return {
                        Component: CreateStaffUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.STAFF_USER_PROFILE,

                        async lazy() {
                            const StaffUserProfile = await import(
                                'pages/users/steppers/staffUserStepper/staffUserProfile'
                            );
                            return {
                                Component: StaffUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,

                        async lazy() {
                            const UserFileUpload = await import(
                                'pages/users/steppers/userFileUpload/userFileUpload'
                            );
                            return {
                                Component: UserFileUpload.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.STAFF_USER}/${PATHS.EDIT}/:user_id?`,
                loader: (args) => protectedLoader(args, Permissions.EDIT_STAFF_USER), // Pass required permission here

                async lazy() {
                    const CreateStaffUser = await import('pages/users/createStaffUsers');
                    return {
                        Component: CreateStaffUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.STAFF_USER_PROFILE,

                        async lazy() {
                            const StaffUserProfile = await import(
                                'pages/users/steppers/staffUserStepper/staffUserProfile'
                            );
                            return {
                                Component: StaffUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,

                        async lazy() {
                            const UserFileUpload = await import(
                                'pages/users/steppers/userFileUpload/userFileUpload'
                            );
                            return {
                                Component: UserFileUpload.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.STAFF_USER}/${PATHS.VIEW}/:user_id?`,
                loader: (args) => protectedLoader(args, Permissions.VIEW_STAFF_USER), // Pass required permission here

                async lazy() {
                    const CreateStaffUser = await import('pages/users/createStaffUsers');
                    return {
                        Component: CreateStaffUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.STAFF_USER_PROFILE,

                        async lazy() {
                            const StaffUserProfile = await import(
                                'pages/users/steppers/staffUserStepper/staffUserProfile'
                            );
                            return {
                                Component: StaffUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,

                        async lazy() {
                            const UserFileUpload = await import(
                                'pages/users/steppers/userFileUpload/userFileUpload'
                            );
                            return {
                                Component: UserFileUpload.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.CONTACT_USER}/${PATHS.CREATE}`,
                loader: (args) => protectedLoader(args, Permissions.ADD_CONTACT_USER), // Pass required permission here

                async lazy() {
                    const CreateContactUser = await import('pages/users/createContactUser');
                    return {
                        Component: CreateContactUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.CONTACT_USER_PROFILE,

                        async lazy() {
                            const ContactUserProfile = await import(
                                'pages/users/steppers/contactUserStepper/contactUserProfile'
                            );
                            return {
                                Component: ContactUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.CONTACT_BILLING_INFO,
                        async lazy() {
                            const ContactBillingInfo = await import(
                                'pages/users/steppers/contactUserStepper/contactBillingInfo'
                            );
                            return {
                                Component: ContactBillingInfo.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.CONTACT_USER}/${PATHS.EDIT}/:user_id?`,
                loader: (args) => protectedLoader(args, Permissions.EDIT_CONTACT_USER), // Pass required permission here

                async lazy() {
                    const CreateContactUser = await import('pages/users/createContactUser');
                    return {
                        Component: CreateContactUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.CONTACT_USER_PROFILE,

                        async lazy() {
                            const ContactUserProfile = await import(
                                'pages/users/steppers/contactUserStepper/contactUserProfile'
                            );
                            return {
                                Component: ContactUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.CONTACT_BILLING_INFO,

                        async lazy() {
                            const ContactBillingInfo = await import(
                                'pages/users/steppers/contactUserStepper/contactBillingInfo'
                            );
                            return {
                                Component: ContactBillingInfo.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.CONTACT_USER}/${PATHS.VIEW}/:user_id?`,
                loader: (args) => protectedLoader(args, Permissions.VIEW_CONTACT_PROFILE), // Pass required permission here

                async lazy() {
                    const CreateContactUser = await import('pages/users/createContactUser');
                    return {
                        Component: CreateContactUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.CONTACT_USER_PROFILE,

                        async lazy() {
                            const ContactUserProfile = await import(
                                'pages/users/steppers/contactUserStepper/contactUserProfile'
                            );
                            return {
                                Component: ContactUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.CONTACT_BILLING_INFO,

                        async lazy() {
                            const ContactBillingInfo = await import(
                                'pages/users/steppers/contactUserStepper/contactBillingInfo'
                            );
                            return {
                                Component: ContactBillingInfo.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.VENDOR_USER}/${PATHS.CREATE}`,
                loader: (args) =>
                    protectedLoader(args, [
                        Permissions.CREATE_VENDOR_USERS,
                        Permissions.CREATE_V3_VENDOR_USERS_ONLY
                    ]),

                async lazy() {
                    const CreateVendorUser = await import('pages/users/createVendorUser');
                    return {
                        Component: CreateVendorUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.VENDOR_USER_PROFILE,

                        async lazy() {
                            const VendorUserProfile = await import(
                                'pages/users/steppers/vendorUserStepper/vendorUserProfile'
                            );
                            return {
                                Component: VendorUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_BILLING_INFO,

                        async lazy() {
                            const VendorBillingInfo = await import(
                                'pages/users/steppers/vendorUserStepper/vendorBillingInfo'
                            );
                            return {
                                Component: VendorBillingInfo.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,

                        async lazy() {
                            const UserFileUpload = await import(
                                'pages/users/steppers/userFileUpload/userFileUpload'
                            );
                            return {
                                Component: UserFileUpload.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_RATES,

                        async lazy() {
                            const VendorRateList = await import(
                                'pages/users/steppers/vendorUserStepper/vendorRateList'
                            );
                            return {
                                Component: VendorRateList.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_MINIMUM_CHARGES,

                        async lazy() {
                            const VendorMinimumChargesStepper = await import(
                                'pages/users/steppers/vendorUserStepper/vendorMinimumCharges'
                            );
                            return {
                                Component: VendorMinimumChargesStepper.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.VENDOR_USER}/${PATHS.EDIT}/:user_id?`,
                loader: (args) =>
                    protectedLoader(args, [
                        Permissions.EDIT_VENDOR_USERS,
                        Permissions.EDIT_V3_VENDOR_USERS_ONLY
                    ]),

                async lazy() {
                    const CreateVendorUser = await import('pages/users/createVendorUser');
                    return {
                        Component: CreateVendorUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.VENDOR_USER_PROFILE,

                        async lazy() {
                            const VendorUserProfile = await import(
                                'pages/users/steppers/vendorUserStepper/vendorUserProfile'
                            );
                            return {
                                Component: VendorUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_BILLING_INFO,

                        async lazy() {
                            const VendorBillingInfo = await import(
                                'pages/users/steppers/vendorUserStepper/vendorBillingInfo'
                            );
                            return {
                                Component: VendorBillingInfo.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,

                        async lazy() {
                            const UserFileUpload = await import(
                                'pages/users/steppers/userFileUpload/userFileUpload'
                            );
                            return {
                                Component: UserFileUpload.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_RATES,

                        async lazy() {
                            const VendorRateList = await import(
                                'pages/users/steppers/vendorUserStepper/vendorRateList'
                            );
                            return {
                                Component: VendorRateList.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_MINIMUM_CHARGES,

                        async lazy() {
                            const VendorMinimumChargesStepper = await import(
                                'pages/users/steppers/vendorUserStepper/vendorMinimumCharges'
                            );
                            return {
                                Component: VendorMinimumChargesStepper.default
                            };
                        }
                    }
                ]
            },
            {
                path: `${PATHS.USERS}/${PATHS.VENDOR_USER}/${PATHS.VIEW}/:user_id?`,
                loader: (args) =>
                    protectedLoader(args, [
                        Permissions.VIEW_VENDOR_USERS,
                        Permissions.VIEW_V3_VENDOR_USERS_ONLY
                    ]),

                async lazy() {
                    const CreateVendorUser = await import('pages/users/createVendorUser');
                    return {
                        Component: CreateVendorUser.default
                    };
                },
                children: [
                    {
                        path: PATHS.VENDOR_USER_PROFILE,

                        async lazy() {
                            const VendorUserProfile = await import(
                                'pages/users/steppers/vendorUserStepper/vendorUserProfile'
                            );
                            return {
                                Component: VendorUserProfile.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_BILLING_INFO,

                        async lazy() {
                            const VendorBillingInfo = await import(
                                'pages/users/steppers/vendorUserStepper/vendorBillingInfo'
                            );
                            return {
                                Component: VendorBillingInfo.default
                            };
                        }
                    },
                    {
                        path: PATHS.FILE_UPLOAD,

                        async lazy() {
                            const UserFileUpload = await import(
                                'pages/users/steppers/userFileUpload/userFileUpload'
                            );
                            return {
                                Component: UserFileUpload.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_RATES,

                        async lazy() {
                            const VendorRateList = await import(
                                'pages/users/steppers/vendorUserStepper/vendorRateList'
                            );
                            return {
                                Component: VendorRateList.default
                            };
                        }
                    },
                    {
                        path: PATHS.VENDOR_MINIMUM_CHARGES,

                        async lazy() {
                            const VendorMinimumChargesStepper = await import(
                                'pages/users/steppers/vendorUserStepper/vendorMinimumCharges'
                            );
                            return {
                                Component: VendorMinimumChargesStepper.default
                            };
                        }
                    }
                ]
            },
            {
                path: PATHS.ACCOUNT_BILLS,
                async lazy() {
                    const AccountingBilling = await import('pages/accounting/billing/billing');
                    return {
                        Component: AccountingBilling.default
                    };
                }
            },
            {
                path: `${PATHS.ACCOUNT_BILLS}/details/:billId`,
                async lazy() {
                    const AccountingBillingDetails = await import(
                        'pages/accounting/billingDetails/billingDetails'
                    );
                    return {
                        Component: AccountingBillingDetails.default
                    };
                }
            },
            {
                path: PATHS.BILLS,
                async lazy() {
                    const AccountingBilling = await import('pages/accounting/billing/billing');
                    return {
                        Component: AccountingBilling.default
                    };
                }
            },
            {
                path: `${PATHS.BILLS}/details/:billId`,
                async lazy() {
                    const AccountingBillingDetails = await import(
                        'pages/accounting/billingDetails/billingDetails'
                    );
                    return {
                        Component: AccountingBillingDetails.default
                    };
                }
            },
            {
                path: 'orders-quotes/details/:requestId',
                async lazy() {
                    const ViewOrderAndQuotePage = await import(
                        'pages/ViewOrderAndQuote/viewOrderAndQuote'
                    );
                    return {
                        Component: ViewOrderAndQuotePage.default
                    };
                }
            }
        ]
    },
    {
        path: 'login',
        async lazy() {
            const LoginPage = await import('pages/login/login');
            return {
                Component: LoginPage.default
            };
        }
    },
    {
        path: 'collaborate-partner',
        async lazy() {
            const RegistrationForm = await import('pages/registrationForm/registrationForm');
            return {
                Component: RegistrationForm.default
            };
        }
    },
    {
        path: 'access-denied',
        async lazy() {
            const UnauthorizedPage = await import('pages/unauthorizedPage/unauthorizedPage');
            return {
                Component: UnauthorizedPage.default
            };
        }
    },
    {
        path: 'typography',
        async lazy() {
            const Typography = await import('pages/typography/typography');
            return {
                Component: Typography.default
            };
        }
    },
    {
        path: '/logout',
        action() {
            // We sign-out in a "resource route" that we can hit from a fetcher.Form
            AuthStore.logout();
            return redirect('/');
        }
    }
];
